<template>
  <div class="container">
    <!-- -->
    <!-- 服务搜索 -->
    <!-- 设备选择 -->
    <!-- 设置预约时间段 -->
    <!-- 预约时间段设置 价格，是否可约-->

    <el-form>
      <el-form-item label="服务" required>
        {{venue.name}}
      </el-form-item>
      <el-form-item label="排期类型" required>
        <el-radio v-model="appoint_type" label="week_day">按周几</el-radio>
         <el-radio v-model="appoint_type" label="time">按时间段</el-radio>
      </el-form-item>
       
      <el-form-item label="生效时间段" required>
       <div>{{start_time.split(' ')[0]}}至{{end_time.split(' ')[0]}} <span v-if="appoint_type == 'week_day' && week_day"> {{weekInfo[week_day -1].name}}</span></div>
        <div>服务预约排期信息生效时间段</div>
      </el-form-item>
      <el-form-item label="开放时间段">
        <el-time-select
          placeholder="起始时间"
          v-model="startTime"
          :picker-options="{
            start: '05:00',
            step: '01:00',
            end: '23:00',
          }"
        >
        </el-time-select>
        至
        <el-time-select
          placeholder="结束时间"
          v-model="endTime"
          :picker-options="{
            start: '05:00',
            step: '01:00',
            end: '23:00',
            minTime: startTime,
          }"
        >
        </el-time-select>
        <div>设置每天开放预约的时间</div>
      </el-form-item>
      <el-form-item label="开放设备" required>
        <el-checkbox-group v-model="checkDevices">
          <el-checkbox :label="device.id" v-for="device in devices" :key="device.id">{{
            device.name
          }}</el-checkbox>
        </el-checkbox-group>
        <div v-if="devices.length == 0">暂无设备</div>
      </el-form-item>

    </el-form>

    <appointmentTable
      ref="appt"
      :startTime="startTime"
      :endTime="endTime"
      :devices="changeDevices"
      type="edit" :datas="appointInfo"
    ></appointmentTable>

    <div class="m-t20">
      <el-button type="success" @click="submitData">修改预约排期</el-button>
      <el-button type="danger" @click="delData">删除预约排期</el-button>
    </div>
  </div>

</template>

<script>
import serviceSelect from "@/components/select/serviceSelect.vue";
import venueSelect from "@/components/select/venueSelect.vue";
import appointmentTable from "../components/appointmentTable.vue";

export default {
  components: {
    serviceSelect,
    venueSelect,
    appointmentTable,
  },
  data() {
    return {
      week_day:'',
      weekInfo: [
        { name: "周一", value: 1 },
        { name: "周二", value: 2 },
        { name: "周三", value: 3 },
        { name: "周四", value: 4 },
        { name: "周五", value: 5 },
        { name: "周六", value: 6 },
        { name: "周七", value: 7 },
      ],
      venue_id: "",
      appoint_type: "time",
      dateRange: [],
      start_time: "",
      end_time: "",
      startTime:'',
      endTime:'',
      changeWeek: [],
      venue: {},
      devices: [],
      changeDevices:[],
      checkDevices:[],
      appointInfo:[]
    };
  },

  mounted() {
    this.venue_id = this.$route.params.venue_id
    this.appoint_type = this.$route.params.type
    this.start_time = this.$route.params.start_time
    this.end_time = this.$route.params.end_time
    this.week_day = this.$route.params.week_day
    if(this.venue_id){
    this.getVenueInfo()
    // this.getDevice()
    this.getAppointmentTemp()
    }
    
  },
  watch:{
    checkDevices:{
        handler:function(nv){
            let devices = []
            this.devices.forEach(d=>{
                if(nv.includes(d.id)){
                    devices.push(d)
                }
            })
            this.changeDevices = devices
        },
        deep:true,
        immediate:true
    }
  },

  methods: {
     getAppointmentTemp() {
      this.$axios({
        url: "/user/serviceAppointTemp/queryInfo",
        params: {
          venue_id: this.venue_id,
          start_time: this.start_time,
          end_time: this.end_time,
          type:this.type
        },
      }).then((res) => {
          this.checkDevices = []
            this.devices = []
        res.data.data.forEach(item=>{
            this.checkDevices.push(item.device.id)
             this.devices.push(item.device)
            item.data = JSON.parse(item.data)
        })
        this.appointInfo = res.data.data;
      });
    },
    getVenueInfo() {
      let url = "/user/mechanism/venue/findById";
      this.$axios({
        url,
        params: { id: this.venue_id },
      }).then((res) => {
       this.venue = res.data.data
      });
    },
    delData(){
        this.$confirm(`确认删除${this.start_time.split(' ')[0]}至${this.end_time.split(' ')[0]}的场地可约排期`).then(()=>{
              let data = {
          type:this.appoint_type,
          venue_id:this.venue_id,
          start_time:this.start_time,
          end_time:this.end_time,
          week_day:this.week_day || 0
      }
          
      let url = "user/serviceAppointTemp/delTemplate";
      this.$axios({
        url,
        method:'post',
        data:data,
      }).then(res=>{
        this.$message("删除成功")
        this.startTime = ''
        this.endTime = ''
        this.$router.back()
      })
        })
    },
    //提交
    submitData(){
      if(!this.venue_id){
        this.$message("请选择场馆")
        return
      }
      let appointInfo = this.$refs.appt.getAppointTemplate()
      if(appointInfo.length == 0){
         this.$message("未设置预约时间信息")
         return
      }
      let data = {
          type:this.appoint_type,
          venue_id:this.venue_id,
          start_time:this.start_time,
          end_time:this.end_time,
          data:appointInfo
      }
      if(this.appoint_type == 'week_day'){
        data.weeks = [this.week_day]
      }
          
      let url = "user/serviceAppointTemp/updateTemplate";
      this.$axios({
        url,
        method:'post',
        data:data,
      }).then(res=>{
        this.$message("更新成功")

        this.startTime = ''
        this.endTime = ''
        this.$router.back()
      })
    },

    getDevice() {
      let url = "user/venue/device/query";
      this.$axios({
        url,
        params: {
          venue_id: this.venue_id,
          status:0
        },
      }).then((res) => {
         this.devices = res.data.data
      });
    },
  },
};
</script>